<template>
  <page-layout>
    <span slot="header-name">{{ $tf({ ru: 'events | person,,1', en: 'person | events' }) }}</span>

    <table-navigation slot="header-content-navigation" :state="state"></table-navigation>

    <el-table
      name="person-events-table"
      class="events-table"
      stripe
      :size="$vars.sizes.table || $vars.sizes.common"
      :data="state.items"
      row-key="id"
      slot="content"
      v-loading="state.loading"
    >
      <el-table-column prop="id" :label="$tf('common.id')" width="80"></el-table-column>
      <el-table-column prop="" :label="$tf('common.detected')" width="176">
        <template slot-scope="{ row }">
          <object-thumbnail :url="row.thumbnail || ''" :frame="row.frame || row.thumbnail" @preview="(v) => showImage(v && v.src)"></object-thumbnail>
        </template>
      </el-table-column>
      <el-table-column :label="$tf('episode')">
        <template slot-scope="{ row }">
          <a :href="getEpisodeEventsItemLink(row)" v-if="row.episode" target="_blank">{{ row.episode }}</a>
        </template>
      </el-table-column>
      <el-table-column :label="$tf('person')">
        <template slot-scope="{ row }">
          <a :href="getPersonEventsUrl(row)" v-if="row.person" target="_blank">{{ row.person }}</a>
        </template>
      </el-table-column>
      <el-table-column min-width="192px" :label="$tf('common.attributes')">
        <template slot-scope="{ row }">
          <div :key="item" v-for="item in getFormattedFeatures(row)">{{ item }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="role" width="160px" :label="`${$tf('common.exact_time')}, ${$tf('common.camera')}`">
        <template slot-scope="{ row }">
          {{ row.created_date | isoStringToDate | formatDate }}
          <br />
          {{ row.created_date | isoStringToDate | formatTime }}
          <br /><br />

          <el-tooltip
            class="tooltip-item"
            effect="dark"
            :content="getCameraGroup(row).name + ' (' + getCamera(row).name + ')'"
            placement="top"
            :open-delay="800"
          >
            <div>
              <span class="link" @click.stop="addArrayFilter('camera_groups', getCamera(row).group)">{{ getCameraGroup(row).name | shortString }}</span>
              (<span class="link" @click.stop="addArrayFilter('cameras', getCamera(row).id)">{{ $filters.shortString(getCamera(row).name, 10) }}</span
              >)
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import TableNavigation from '@/components/tables/navigation.vue';
import ObjectThumbnail from '../objects/thumbnail.vue';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import HeaderBackButton from '@/components/common/header.back.button';
import PageLayout from '@/components/page/layout';

function alphabeticalSort(a, b) {
  return a.localeCompare(b);
}

export default {
  components: {
    PageLayout,
    ObjectThumbnail,
    TableNavigation,
    HeaderBackButton
  },
  name: 'page-person-events',
  computed: {
    model() {
      return 'person_events';
    },
    state() {
      return this.$store.state.person_events;
    },
    stats() {
      return this.$store.state.stats;
    }
  },
  mounted() {},
  methods: {
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    showImage(url) {
      this.$store.dispatch('showImage', { src: url });
    },
    getCamera(item) {
      return this.$store.state.cameras.items.find((v) => v.id === item.camera) || { name: this.$tf('camera | not_found,,1') };
    },
    getCameraGroup(item) {
      let camera = this.getCamera(item);
      return this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || { name: '' };
    },
    getEpisodeEventsItemLink(item) {
      let filter = _.pickBy(Object.assign({}, this.$store.state.faces_events.filter.empty, { episode: item.episode }), (v) => !!v);
      return '#/events/faces/filter/' + qs.stringify(filter, { sort: alphabeticalSort });
    },
    getPersonEventsUrl(v) {
      let filter = Object.assign({}, this.$store.state.person_events.filter.empty, { person_in: v.person }),
        cleanFilter = _.pickBy(filter, (v) => !!v);
      return '#/person-events/filter/' + qs.stringify(cleanFilter, { sort: alphabeticalSort });
    },
    beforeDestroy() {
      this.$cache.enable(false);
    },
    getFormattedFeatures(v) {
      const { attributes } = v,
        features = attributes,
        result = [],
        items = features ? Object.keys(features).sort() : {};

      for (let k in items) {
        const i = items[k],
          v = features[i],
          translatedName = this.$tf(i),
          desc =
            v && typeof v === 'object'
              ? this.$tf(v.name) + ' (' + translatedName + ')' + ': ' + v.confidence.toFixed(2)
              : translatedName + ': ' + (typeof v === 'number' ? Math.round(v) : v);
        v && result.push(desc);
      }
      return result;
    },
    addArrayFilter(name, id) {
      if (this.state.filter.current[name].indexOf(id) === -1) this.state.filter.current[name].push(id);
    }
  }
};
</script>

<style lang="stylus">
.event-reaction-content {
  margin-top: 1rem;
}

.filter--page-limit {
  display: inline-block;
  width: 5rem;
}

.tag-loading {
  cursor: none !important;
  opacity: .2;
}

.persons {
  justify-content: center;
}
</style>
