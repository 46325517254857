var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('span',{attrs:{"slot":"header-name"},slot:"header-name"},[_vm._v(_vm._s(_vm.$tf({ ru: 'events | person,,1', en: 'person | events' })))]),_c('table-navigation',{attrs:{"slot":"header-content-navigation","state":_vm.state},slot:"header-content-navigation"}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}],staticClass:"events-table",attrs:{"slot":"content","name":"person-events-table","stripe":"","size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items,"row-key":"id"},slot:"content"},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('common.id'),"width":"80"}}),_c('el-table-column',{attrs:{"prop":"","label":_vm.$tf('common.detected'),"width":"176"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('object-thumbnail',{attrs:{"url":row.thumbnail || '',"frame":row.frame || row.thumbnail},on:{"preview":function (v) { return _vm.showImage(v && v.src); }}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('episode')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.episode)?_c('a',{attrs:{"href":_vm.getEpisodeEventsItemLink(row),"target":"_blank"}},[_vm._v(_vm._s(row.episode))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":_vm.$tf('person')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.person)?_c('a',{attrs:{"href":_vm.getPersonEventsUrl(row),"target":"_blank"}},[_vm._v(_vm._s(row.person))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"min-width":"192px","label":_vm.$tf('common.attributes')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.getFormattedFeatures(row)),function(item){return _c('div',{key:item},[_vm._v(_vm._s(item))])})}}])}),_c('el-table-column',{attrs:{"prop":"role","width":"160px","label":((_vm.$tf('common.exact_time')) + ", " + (_vm.$tf('common.camera')))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("isoStringToDate")(row.created_date)))+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm._f("isoStringToDate")(row.created_date)))+" "),_c('br'),_c('br'),_c('el-tooltip',{staticClass:"tooltip-item",attrs:{"effect":"dark","content":_vm.getCameraGroup(row).name + ' (' + _vm.getCamera(row).name + ')',"placement":"top","open-delay":800}},[_c('div',[_c('span',{staticClass:"link",on:{"click":function($event){$event.stopPropagation();_vm.addArrayFilter('camera_groups', _vm.getCamera(row).group)}}},[_vm._v(_vm._s(_vm._f("shortString")(_vm.getCameraGroup(row).name)))]),_vm._v(" ("),_c('span',{staticClass:"link",on:{"click":function($event){$event.stopPropagation();_vm.addArrayFilter('cameras', _vm.getCamera(row).id)}}},[_vm._v(_vm._s(_vm.$filters.shortString(_vm.getCamera(row).name, 10)))]),_vm._v(") ")])])]}}])})],1),_c('table-navigation',{attrs:{"slot":"footer-content-navigation","state":_vm.state},slot:"footer-content-navigation"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }